@layer modules, ui, base;
@layer base {
  .FooterMenuList_title__QN9R_ {
  margin-top: 0;
  margin-bottom: calc(var(--sk-space-32)/2);
  font-size: 0.875rem;
  font-weight: var(--font-weight-semibold);
}

.FooterMenuList_link__zJJdl {
  display: inline-block;
  padding: var(--sk-space-16);
  color: var(--sk-color-white);
  font-size: var(--typo-body-3-font-size);
  line-height: var(--typo-body-2-line-height);
  text-decoration: none;
}

@media (max-width: 47.999rem) { /* --small-and-less-only */
  .FooterMenuList_main__VKfN9 {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media (max-width: 63.999rem) { /* --medium-and-less-only */
  .FooterMenuList_main__VKfN9 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .FooterMenuList_item__QZnlO {
    padding: 0 16px;
  }

  .FooterMenuList_title__QN9R_ {
    display: none;
  }
}

@media (min-width: 64rem) { /* --large-viewport */
  .FooterMenuList_title__QN9R_::after {
    content: "";
    display: block;
    width: var(--sk-space-24);
    margin-bottom: var(--sk-space-24);
    padding-bottom: var(--sk-space-24);
    border-bottom: thin solid var(--sk-color-white);
  }

  .FooterMenuList_link__zJJdl {
    padding: 0;
  }
}

}
@layer base {
  .FooterMenu_main__7HAwT {
  padding: calc(var(--sk-space-32)/2);
  border-bottom: 1px solid #dbdbdb;
}

.FooterMenu_menuItem__desktop__TI_pe {
  display: none;
}

@media (max-width: 63.999rem) { /* --medium-and-less-only */
  .FooterMenu_menuItem__oPS7U {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .FooterMenu_menuItem__desktop__TI_pe {
    display: none;
  }
}

@media (min-width: 64rem) { /* --large-viewport */
  .FooterMenu_main__7HAwT {
    display: flex;
    padding: var(--sk-space-48) 0;
    font-size: 0.875rem;
  }

  .FooterMenu_menuItem__oPS7U {
    flex: 1 0 auto;
  }

  .FooterMenu_menuItem__desktop__TI_pe {
    display: block;
  }
}

}
@layer base {
  .FooterLegals_main__QJw89 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: calc(var(--sk-space-32)/2);
  font-size: 0.8125rem;
}

.FooterLegals_links__ncIjV {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.FooterLegals_link__klHBy {
  display: inline-block;
  padding: var(--sk-space-16);
  color: var(--themeHeaderColor, var(--sk-color-white));
  font-size: var(--typo-body-3-font-size);
  line-height: var(--typo-body-2-line-height);
  text-decoration: none;
}

.FooterLegals_link__small__OVhlQ {
  font-size: 13px;
  line-height: var(--typo-body-3-line-height);
}

.FooterLegals_copyright__zAj6j {
  margin-top: calc(var(--sk-space-32)/2);
  color: var(--themeHeaderColor, var(--sk-color-white));
  line-height: 1.125rem;
  text-align: center;
}

@media (min-width: 48rem) { /* --medium-viewport */
  .FooterLegals_main__QJw89 {
    padding: calc(var(--sk-space-32)/2) 0;
  }

  .FooterLegals_links__ncIjV {
    flex-direction: row;
    /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
    flex-wrap: wrap;
  }

  .FooterLegals_item__mPfMv {
    line-height: inherit;
  }

  .FooterLegals_copyright__zAj6j {
    text-align: right;
  }
}

@media (min-width: 64rem) { /* --large-viewport */
  .FooterLegals_main__QJw89 {
    flex-direction: row;
    padding: var(--sk-space-48) 0;
  }

  .FooterLegals_item__mPfMv {
    padding-right: 2rem;
  }

  .FooterLegals_link__klHBy {
    padding: 0;
  }

  .FooterLegals_copyright__zAj6j {
    margin-top: inherit;
  }
}

/******************************
  UI Shift
*******************************/
/* stylelint-disable plugin/selector-bem-pattern */

[data-theme="jobteaser-uishift"] .FooterLegals_link__klHBy,
[data-theme="jobteaser-uishift"] .FooterLegals_copyright__zAj6j {
  color: var(--sk-color-white);
}

}
@layer base {
  .Icon_hdr-Icon__KJAoi {
  --iconSize: 20px;
  width: var(--iconSize);
  min-width: var(--iconSize);
  height: var(--iconSize);
  fill: currentcolor;
  vertical-align: middle;
}

.Icon_hdr-Icon--isBig__nDZPe {
  --iconSize: 24px;
}

}
@layer base {
  .NewTag_main__8fEb1 {
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  padding: 0 var(--sk-space-8);
  border-radius: var(--sk-radius-8);
  background-color: var(--sk-color-purple-100);
  color: var(--sk-color-purple-700);
}

.NewTag_label__mACDJ {
  overflow: hidden;
}

}
@layer base {
  /* those two rules because Prettier is set to 120 and break some stylelint rules around line 260 */
/* stylelint-disable selector-descendant-combinator-no-non-space */
/* stylelint-disable indentation */
.DropdownMenu_hdr-DropdownMenu__ujkQp {
  display: inline-flex;
  font-size: 1rem;
}

.DropdownMenu_hdr-DropdownMenu-item__w5MXE {
  display: flex;
  outline: none;
}

.DropdownMenu_hdr-DropdownMenu-item__w5MXE:focus-within {
  pointer-events: none;
}

.DropdownMenu_hdr-DropdownMenu-link__DatIX {
  display: flex;
  position: relative;
  align-items: center;
  padding: var(--sk-space-12);
  border: none;
  background-color: transparent;
  color: var(--themeButtonTextColor, var(--sk-color-black));
  font-family: var(--fontFamily);
  font-size: var(--smallBody);
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
}

.DropdownMenu_hdr-DropdownMenu-link__DatIX::after,
.DropdownMenu_hdr-DropdownMenu-link__DatIX .DropdownMenu_hdr-DropdownMenu-arrowIcon__Z_TaA {
  margin: 0.125rem 0 0 0.5rem;
  font-size: 1.5em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: var(--font-weight-regular);
  font-feature-settings: "liga";
  text-rendering: optimizelegibility;
  word-wrap: normal;
  vertical-align: middle;
}

.DropdownMenu_hdr-DropdownMenu-link--active__UbBy4 {
  color: var(--themeButtonTextColor, var(--color-primary));
}

.DropdownMenu_hdr-DropdownMenu-link--active__UbBy4::after,
.DropdownMenu_hdr-DropdownMenu-link--active__UbBy4 .DropdownMenu_hdr-DropdownMenu-arrowIcon__Z_TaA {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: var(--sk-space-12);
  width: calc(100% - var(--sk-space-24));
  height: 0.125rem;
  margin: 0;
  padding: 0;
  background-color: var(--themeButtonTextColor, var(--color-primary));
}

.DropdownMenu_hdr-DropdownMenu-submenu-trigger__gH2PE.dropdown--open .DropdownMenu_hdr-DropdownMenu-submenu-trigger___icon__menu__Jbdcn,
.DropdownMenu_hdr-DropdownMenu-submenu-trigger__gH2PE:not(.dropdown--open) .DropdownMenu_hdr-DropdownMenu-submenu-trigger___icon__cross__n7Gro {
  display: none;
}

.DropdownMenu_hdr-DropdownMenu-submenu-trigger__gH2PE.dropdown--open .DropdownMenu_hdr-DropdownMenu-submenu-trigger___icon__cross__n7Gro,
.DropdownMenu_hdr-DropdownMenu-submenu-trigger__gH2PE:not(.dropdown--open) .DropdownMenu_hdr-DropdownMenu-submenu-trigger___icon__menu__Jbdcn {
  display: block;
}

.DropdownMenu_hdr-DropdownMenu-submenu__H6xbs {
  display: flex;
  position: absolute;
  top: 100%;
  right: 0;
  flex-direction: column;
  width: 1px;
  height: 1px;
  gap: var(--sk-space-8);
  margin: var(--sk-space-8) 0 0;
  padding: 0;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  border-radius: var(--sk-radius-4);
  box-shadow: var(--shadow-default);
  list-style: none;
  pointer-events: auto;
}

.DropdownMenu_hdr-DropdownMenu-item__w5MXE:focus-within .DropdownMenu_hdr-DropdownMenu-submenu__H6xbs {
  z-index: var(--z-up-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  height: auto;
  padding: var(--sk-space-16);
  clip: auto;
  background: var(--sk-color-white);
}

.DropdownMenu_hdr-DropdownMenu-submenuLink__gS0fM {
  display: flex;
  align-items: center;
  gap: var(--sk-space-8);
  padding: var(--sk-space-8);
  color: inherit;
  text-decoration: none;
}

.DropdownMenu_hdr-DropdownMenu-submenuLink--active__FTAy4 p {
  font-weight: var(--sk-font-weights-poppins-3);
}

.DropdownMenu_hdr-DropdownMenu-submenuLink__gS0fM:hover {
  background-color: var(--sk-color-grey-025);
}

.DropdownMenu_hdr-DropdownMenu-submenuLink--withIcon__YljKH svg {
  width: var(--sk-size-20);
  min-width: var(--sk-size-20);
  height: var(--sk-size-20);
}

.DropdownMenu_hdr-DropdownMenu-divider__hONbo {
  display: flex;
}

.DropdownMenu_hdr-DropdownMenu-submenuLinkIcon__A1nH5 {
  padding: 0 1rem 0 0.5rem;
  stroke: var(--themeMainColor, var(--color-primary));
}

.DropdownMenu_hdr-DropdownMenu-submenuLogo__HFkce {
  width: var(--sk-size-20);
  height: var(--sk-size-20);
  border-radius: var(--sk-radius-4);
}

.DropdownMenu_hdr-DropdownMenu-linkTextWrapper__QxG8B {
  margin: 0;
  font-weight: 400;
}

.DropdownMenu_hdr-DropdownMenu-linkTitle__VBqgQ {
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.DropdownMenu_hdr-DropdownMenu-linkTitle--active__1m__Y {
  font-weight: var(--font-weight-bold);
}

.DropdownMenu_hdr-DropdownMenu-linkText__aGQ5o {
  margin: 0;
  color: var(--sk-color-grey-500);
  font-size: 0.75rem;
  line-height: 1rem;
}

.DropdownMenu_hdr-DropdownMenu-linkIcon__EG__u {
  display: flex;
  align-items: center;
}

.DropdownMenu_hdr-DropdownMenu-item--user__vytk9 {
  font-weight: var(--font-weight-regular);
}

.DropdownMenu_hdr-DropdownMenu-item--userActive__Rwv13 .DropdownMenu_hdr-DropdownMenu-link__DatIX {
  font-weight: var(--font-weight-bold);
}

@media (max-width: 63.999rem) {
  /* --medium-and-less-only */
  .DropdownMenu_hdr-DropdownMenu-linkLabel--hidden__eOlSw {
    display: none;
  }

  .DropdownMenu_hdr-DropdownMenu-item__w5MXE:focus-within .DropdownMenu_hdr-DropdownMenu-submenu__H6xbs {
    left: 30px;
    width: calc(100% - 30px * 2);
  }

  .DropdownMenu_hdr-DropdownMenu-arrowIcon__Z_TaA {
    display: none;
  }

  .DropdownMenu_hdr-DropdownMenu-item--user__vytk9 .DropdownMenu_hdr-DropdownMenu-link__DatIX::after {
    content: none;
  }

  .DropdownMenu_hdr-DropdownMenu-item--user__vytk9 .DropdownMenu_hdr-DropdownMenu-submenu__H6xbs,
  .DropdownMenu_hdr-DropdownMenu-item--user__vytk9:focus-within .DropdownMenu_hdr-DropdownMenu-submenu__H6xbs {
    --submenuSpacer: 2vw;
    top: var(--sk-space-56);
    left: var(--submenuSpacer);
    box-sizing: border-box;
    width: calc(100vw - var(--submenuSpacer) * 2);
  }
}

@media (min-width: 64rem) {
  /* --large-viewport */
  .DropdownMenu_hdr-DropdownMenu__ujkQp {
    position: relative;
    margin: 0;
    padding: 0;
    font-size: 0.875rem;
    font-weight: var(--font-weight-semibold);
  }

  .DropdownMenu_hdr-DropdownMenu-item--userActive__Rwv13 {
    position: relative;
    height: var(--sk-space-64);
  }

  .DropdownMenu_hdr-DropdownMenu-item--userActive__Rwv13::after,
  .DropdownMenu_hdr-DropdownMenu-item--userActive__Rwv13 .DropdownMenu_hdr-DropdownMenu-arrowIcon__Z_TaA {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: var(--sk-space-12);
    width: calc(100% - var(--sk-space-24));
    height: 0.125rem;
    margin: 0;
    padding: 0;
    background-color: var(--sk-color-black);
  }

  .DropdownMenu_hdr-DropdownMenu-item__w5MXE:focus-within .DropdownMenu_hdr-DropdownMenu-submenu__H6xbs {
    box-sizing: border-box;
    width: 345px;
  }

  .DropdownMenu_hdr-DropdownMenu-link--active__UbBy4 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .DropdownMenu_hdr-DropdownMenu-linkIcon__EG__u {
    display: flex;
    padding-right: 0.5rem;
  }

  .DropdownMenu_hdr-DropdownMenu-item__w5MXE:focus-within .DropdownMenu_hdr-DropdownMenu-link__DatIX:not(.DropdownMenu_hdr-DropdownMenu-link--active__UbBy4)::after,
  .DropdownMenu_hdr-DropdownMenu-item__w5MXE:focus-within
    .DropdownMenu_hdr-DropdownMenu-link__DatIX:not(.DropdownMenu_hdr-DropdownMenu-link--active__UbBy4)
    .DropdownMenu_hdr-DropdownMenu-arrowIcon__Z_TaA {
    /* stylelint-disable-line max-line-length */
    transform: rotate(180deg);
    transition: transform 0.1s ease;
  }
}

/******************************
  UI Shift
*******************************/
/* stylelint-disable plugin/selector-bem-pattern */

[data-theme="jobteaser-uishift"] .DropdownMenu_hdr-DropdownMenu-link__DatIX {
  color: var(--sk-color-black);
}

[data-theme="jobteaser-uishift"] .DropdownMenu_hdr-DropdownMenu-link--active__UbBy4 {
  color: var(--color-primary);
}

[data-theme="jobteaser-uishift"] .DropdownMenu_hdr-DropdownMenu-link--active__UbBy4::after {
  background-color: var(--color-primary);
}

[data-theme="jobteaser-uishift"] .DropdownMenu_hdr-DropdownMenu-submenu__H6xbs,
[data-theme="jobteaser-uishift"] .DropdownMenu_hdr-DropdownMenu-submenuLink__gS0fM {
  border-radius: var(--sk-radius-8);
}

[data-theme="jobteaser-uishift"] .DropdownMenu_hdr-DropdownMenu-submenuLinkIcon__A1nH5 {
  stroke: var(--sk-color-black);
}

}
@layer base {
  .Header_main__Zk9sm {
  --mj-breakpoint: 80rem;
  --font-weight-bold: 700;
  --font-weight-semibold: 600;
  --font-weight-regular: 400;
  --typo-body-2-font-size: 16px;
  --typo-body-2-line-height: 24px;
  --typo-body-3-font-size: 14px;
  --typo-body-3-line-height: 24px;
  --color-primary: #4dc942; /* Legacy Green */
  --shadow-default: 0 4px 8px rgb(118 118 120 / 20%);
  position: relative;
  z-index: var(--z-index-website-menu);
  background-color: var(--themeMainColor, var(--sk-color-white));
  box-shadow: var(--shadow-default);
}

.Header_nav__IP0B3 {
  --layout-size: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto 1fr auto;
  max-width: var(--layout-size);
}

.Header_link__lueRy {
  color: var(--color-grey--900);
  text-decoration: none;
}

.Header_logo___onO5 {
  display: flex;
  grid-column-start: 1;
  align-items: center;
}

.Header_dropdown__b9_DX {
  display: flex;
  grid-column-start: 2;
}

.Header_topNav__T8_X2 {
  grid-column: 1 / span 2;
  grid-row-start: 2;
}

.Header_primaryNav__lM5sM {
  grid-column: 1 / span 2;
  grid-row-start: 3;
}

.Header_primaryNav__hidden__JAZy2 {
  visibility: hidden;
}

.Header_language__wLGkn {
  grid-column: 1 / span 2;
  grid-row-start: 4;
}

@media (max-width: 63.999rem) {
  /* --medium-and-less-only */

  .Header_main__Zk9sm .Header_nav__IP0B3.nav--open {
    position: fixed;
    z-index: inherit;
    top: 0;
    left: 0;
    width: 100vw;
    width: 100dvw;
    height: 100vh;
    height: 100dvh;
    overflow-y: auto;
    background-color: inherit;
  }

  .Header_main__Zk9sm .Header_nav__IP0B3:not(.nav--open) .Header_topNav__T8_X2,
  .Header_main__Zk9sm .Header_nav__IP0B3:not(.nav--open) .Header_primaryNav__lM5sM,
  .Header_main__Zk9sm .Header_nav__IP0B3:not(.nav--open) .Header_language__wLGkn,
  .Header_main__Zk9sm .Header_nav__IP0B3.nav--open .Header_account__jNuE2 {
    display: none;
  }

  .Header_dropdown__b9_DX {
    flex-direction: row-reverse;
  }

  .Header_primaryNav__lM5sM {
    padding-top: var(--sk-space-16);
    padding-bottom: var(--sk-space-40);
  }

  .Header_topNav__T8_X2 + .Header_primaryNav__lM5sM {
    display: flex;
    align-items: flex-end;
    padding-top: var(--sk-space-48);
  }

  .Header_dropdownButton__Geqm7 {
    display: flex;
    margin-right: var(--sk-space-12);
  }

  .Header_app-Header__dropdownMenu__Lice9 {
    visibility: hidden;
    position: absolute;
    width: 100%;
  }

  .Header_language__wLGkn {
    padding: var(--sk-space-32) var(--sk-space-24);
  }
}

/* Only for devices with small screen height */

@media (max-height: 699px) and (max-width: 1023px) {
  .Header_language__wLGkn {
    padding-top: var(--sk-space-48);
  }
}

@media (min-width: 64rem) {
  /* --large-viewport */
  .Header_main__fixed__saFy6 {
    position: fixed;
    top: 0;
    width: 100%;
  }

  .Header_header--anonymousUser__h2s0j {
    background: linear-gradient(0deg, var(--sk-color-white) 61.54%, var(--sk-color-grey-700) 61.54%);
  }

  .Header_nav__IP0B3 {
    --layout-size: var(--mj-breakpoint);
    box-sizing: border-box;
    grid-template-columns: auto 1fr auto auto;
    grid-template-rows: auto auto;
    margin: auto;
    padding: 0 var(--sk-space-24);
  }

  .Header_logo___onO5 {
    grid-column: 1;
    grid-row-start: 2;
  }

  .Header_app-Header__logo__Ikfct {
    height: var(--sk-space-32);
  }

  .Header_dropdown__b9_DX {
    grid-column: 3;
    grid-row-start: 2;
  }

  .Header_topNav__T8_X2 {
    grid-column: 1 / span 4;
    grid-row-start: 1;
  }

  .Header_primaryNav__lM5sM {
    grid-column: 2;
    grid-row-start: 2;
    text-align: right;
  }

  .Header_language__wLGkn {
    display: flex;
    grid-column: 4;
    grid-row-start: 2;
  }

  .Header_dropdownButton__Geqm7 {
    display: none;
  }
}

/******************************
  UI Shift
*******************************/
/* stylelint-disable plugin/selector-bem-pattern */

[data-theme="jobteaser-uishift"] .Header_main__Zk9sm {
  --color-primary: var(--sk-color-green-500);
  background-color: var(--sk-color-white);
}

[data-theme="jobteaser-uishift"] .Header_logo___onO5 path {
  fill: var(--sk-color-black);
}

@media (min-width: 64rem) {
  /* --large-viewport */
  [data-theme="jobteaser-uishift"] .Header_header--anonymousUser__h2s0j {
    background: linear-gradient(0deg, var(--sk-color-white) 61.54%, var(--sk-color-grey-700) 61.54%);
  }
}

/* we just display the logo on the printed version of the page */

@media print {
  .Header_nav__IP0B3 > :not(.Header_logo___onO5) {
    display: none;
  }

  .Header_logo___onO5 {
    height: var(--sk-space-48);
    margin: auto;
  }
}

}
@layer base {
  .LoginButton_main__uud3M {
  display: flex;
  align-items: center;
  padding: var(--sk-space-12);
  border: none;
  background-color: transparent; /* button reset */
  color: var(--sk-color-black);
  font-family: var(--fontFamily);  
  font-size: var(--sk-font-size-1); /* 14px */
  line-height: var(--sk-line-heights-5); /* 16px */
  white-space: nowrap;
  cursor: pointer;
}

/* The login button is visible when the nav is displayed in the burger menu (mobile/tablet) */
.LoginButton_main___inNav__a3ZZs {
  width: 100%;
  margin: 0 var(--sk-space-12);
}

@media (min-width: 64rem) { /* --large-viewport */
  /* The login button is hidden when the nav is displayed in the website header (desktop) */
  .LoginButton_main___inNav__a3ZZs {
    display: none;
  }
}

.LoginButton_icon__LXIqs {
  display: flex;
  align-items: center;
}

.LoginButton_label__Agi_d {
  margin-left: 0.5rem;
}

.LoginButton_label___visibleOnDesktopOnly__6As_L {
  /* screen reader only */
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  border: 0;
  white-space: nowrap;
}

@media (min-width: 64rem) {
/* disable screen reader only */
  .LoginButton_label___visibleOnDesktopOnly__6As_L {
    position: initial;
    width: initial;
    height: initial;
    padding: initial;
    overflow: initial;
    clip: initial;
    clip-path: initial;
    border: initial;
    white-space: initial;
  }
}

}
@layer base {
  .Footer_main__ESpFn {
  --mj-breakpoint: 80rem;
  --font-weight-bold: 700;
  --font-weight-semibold: 600;
  --font-weight-regular: 400;
  --typo-body-2-font-size: 16px;
  --typo-body-2-line-height: 24px;
  --typo-body-3-font-size: 14px;
  --typo-body-3-line-height: 24px;
  margin-top: auto;
  background-color: var(--themeHeaderTextColor, var(--sk-color-black));
  color: var(--sk-color-white);
}

.Footer_main__ESpFn ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.Footer_brand__SLiAO {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: calc(var(--sk-space-32)/2) 0;
  border-bottom: 0.0625em solid #dbdbdb;
}

.Footer_brand__SLiAO svg path {
  fill: var(--sk-color-white);
}

.Footer_locales__L2OwV {
  margin: 1rem 0;
  text-align: center;
}

@media (max-width: 47.999rem) { /* --small-and-less-only */
  .Footer_logo__XW1BB {
    height: 1.5rem;
  }

  .Footer_logo__smallScreenBottomMargin__sGMQT {
    margin-bottom: var(--sk-space-16);
  }
}

@media (min-width: 48rem) { /* --medium-viewport */
  .Footer_container__z6X6Q {
    --layout-size: 100%;
    box-sizing: border-box;
    width: 100%;
    max-width: var(--layout-size);
    margin: auto;
    padding: 0 var(--sk-space-32);
  }

  .Footer_brand__SLiAO {
    flex-direction: row;
  }

  .Footer_brandContainer__5rmxA {
    display: flex;
  }

  .Footer_locales__L2OwV {
    margin: 0 1rem 0 0;
  }
}

@media (min-width: 64rem) { /* --large-viewport */
  .Footer_container__z6X6Q {
    --layout-size: var(--mj-breakpoint);
    padding: 0 var(--sk-space-24);
  }

  .Footer_brand__SLiAO {
    padding: var(--sk-space-48) 0;
  }
}

/******************************
  UI Shift
*******************************/
/* stylelint-disable plugin/selector-bem-pattern */

[data-theme="jobteaser-uishift"] .Footer_main__ESpFn {
  background-color: var(--sk-color-black);
}

/* the footer is NOT displayed on the printed version of the page */

@media print {
  .Footer_main__ESpFn {
    display: none;
  }
}

}
@layer base {
  .Nav_app-Nav__YDPug {
  width: 100%;
  margin: 0;
  padding: 0;
}

.Nav_app-Nav__item__Ns0tT {
  display: inherit;
  align-items: center;
}

.Nav_app-Nav__link__W3ONF {
  display: block;
  position: relative;
  box-sizing: border-box;
  padding: var(--sk-space-16) 0;
  color: var(--themeButtonTextColor, var(--sk-color-black));
  font-size: var(--typo-body-2-font-size);
  font-weight: var(--black);
  line-height: var(--typo-body-2-line-height);
  text-decoration: none;
  white-space: nowrap;
}

.Nav_app-Nav__link__W3ONF:hover {
  color: var(--themeButtonTextColor, var(--color-primary));
}

.Nav_app-Nav__link--active__HuxqT {
  color: var(--themeButtonTextColor, var(--color-primary));
}

.Nav_app-Nav__newTag__ZpufN {
  margin-left: var(--sk-space-8);
}

@media (max-width: 63.999rem) { /* --medium-and-less-only */
  .Nav_app-Nav__link__W3ONF {
    margin: 0 var(--sk-space-24);
    border-bottom: thin solid var(--themeButtonTextColor, var(--sk-color-grey-100));
  }

  .Nav_app-Nav__personasList__rsQGs {
    margin-top: var(--sk-space-16);
    padding: 0;
    list-style: none;
  }

  .Nav_app-Nav__personasItem--active__HJSRA {
    display: none;
  }

  .Nav_app-Nav__personasLink__XjLP_ {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 var(--sk-space-24);
    padding: var(--sk-space-16) 0;
    border-bottom: thin solid var(--sk-color-grey-100);
    color: var(--sk-color-black);
    font-size: var(--typo-body-2-font-size);
    line-height: var(--typo-body-2-line-height);
    text-decoration: none;
  }
}

@media (min-width: 64rem) { /* --large-viewport */
  .Nav_app-Nav__personas__d2Ecs {
    --layout-size: var(--mj-breakpoint);
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    max-width: var(--layout-size);
    min-height: 40px;
    margin: auto;
  }

  .Nav_app-Nav__personasList__rsQGs {
    display: flex;
    flex-grow: 1;
    gap: 0 var(--sk-space-24);
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .Nav_app-Nav__personasItem__HuBH4 {
    display: flex;
  }

  .Nav_app-Nav__personasLink__XjLP_ {
    position: relative;
    padding: var(--sk-space-8) 0;
    color: var(--sk-color-black);
    font-size: var(--typo-body-3-font-size);
    line-height: var(--typo-body-3-line-height);
    text-decoration: none;
    text-transform: capitalize;
  }

  .Nav_app-Nav__personasLink--active__rGZ4z {
    font-weight: var(--font-weight-bold);
  }

  .Nav_app-Nav__personasLink__XjLP_:not(.Nav_app-Nav__personasLink--active__rGZ4z) {
    color: var(--sk-color-white);
  }

  .Nav_app-Nav__personasLink__XjLP_:hover,
  .Nav_app-Nav__personasLink--active__rGZ4z {
    color: var(--color-primary);
  }

  .Nav_app-Nav__personasLink__XjLP_:hover::after,
  .Nav_app-Nav__personasLink--active__rGZ4z::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 2px solid var(--color-primary);
  }

  .Nav_app-Nav__personasLinkIcon__7ECJk {
    display: none;
  }

  .Nav_app-Nav__YDPug {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0;
  }

  .Nav_app-Nav__link__W3ONF {
    display: flex;
    align-items: center;
    height: var(--sk-space-64);
    padding: 0 0.75rem;
    font-size: 0.875rem;
    font-weight: var(--font-weight-regular);
    text-align: center;
  }

  .Nav_app-Nav__link--active__HuxqT {
    font-weight: var(--font-weight-bold);
  }

  .Nav_app-Nav__link__W3ONF:hover::after,
  .Nav_app-Nav__link--active__HuxqT::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: var(--sk-space-12);
    width: calc(100% - var(--sk-space-24));
    height: 0.125rem;
    background-color: var(--themeButtonTextColor, var(--color-primary));
  }
}

/******************************
  UI Shift
*******************************/
/* stylelint-disable plugin/selector-bem-pattern */
[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--offers__u7Yqo { order: 1; }
[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--companies__QU_5_ { order: 2; }
[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--events__IblND { order: 3; }
[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--community__17gx_ { order: 4; }
[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--jobs__BR2xW { order: 5; }
[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--jobRoles__aFmJi { order: 5; }
[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--advices__D7eCm { order: 6; }
[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--handbook__rAQDn { order: 7; }
[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--ressources__aK2kz { order: 8; }
[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--appointment__J_xa_ { order: 9; }
[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--careerExplorer__q_aL5 { order: 10; }
[data-theme="jobteaser-uishift"] .Nav_app-Nav__item--login__OX5tA { order: 11; }

[data-theme="jobteaser-uishift"] .Nav_app-Nav__link__W3ONF {
  color: var(--sk-color-black);
  font-weight: var(--font-weight-regular);
}

[data-theme="jobteaser-uishift"] .Nav_app-Nav__link--active__HuxqT {
  font-weight: var(--font-weight-semibold);
}

[data-theme="jobteaser-uishift"] .Nav_app-Nav__linkLang__MY7Bx {
  color: var(--sk-color-white);
}

@media (max-width: 63.999rem) { /* --medium-and-less-only */
  [data-theme="jobteaser-uishift"] .Nav_app-Nav__YDPug {
    display: flex;
    flex-direction: column;
  }

  [data-theme="jobteaser-uishift"] .Nav_app-Nav__link__W3ONF {
    width: 100%;
    border-bottom-color: var(--sk-color-grey-100);
  }
}

@media (min-width: 64rem) { /* --large-viewport */
  [data-theme="jobteaser-uishift"] .Nav_app-Nav__item--dashboard__1NSLW {
    display: none;
  }

  [data-theme="jobteaser-uishift"] .Nav_app-Nav__link__W3ONF:hover::after,
  [data-theme="jobteaser-uishift"] .Nav_app-Nav__link--active__HuxqT::after {
    background-color: var(--sk-color-black);
  }
}

}
@layer base {
  .DropdownCommunity_hdr-DropdownCommunity__X_wja {
  display: inline-block;
  position: relative;
  outline: 0;
  cursor: pointer;
}

.DropdownCommunity_hdr-DropdownCommunity--mobile__Qepmd {
  position: static;
}

.DropdownCommunity_hdr-DropdownCommunity--header__KvoZ9 {
  width: 100%;
}

.DropdownCommunity_hdr-DropdownCommunity__content___1isw {
  display: none;
  visibility: hidden;
  position: absolute;
  z-index: var(--z-up-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  top: 80%;
  right: 0;
  box-sizing: border-box;
  min-width: 270px;
  padding: 0 1.875rem 1.875rem;
  transition: visibility 0.5s;
  opacity: 0;
  pointer-events: auto;
}

.DropdownCommunity_hdr-DropdownCommunity__X_wja:hover .DropdownCommunity_hdr-DropdownCommunity__content___1isw,
.DropdownCommunity_hdr-DropdownCommunity__content___1isw:hover {
  display: block;
  visibility: visible;
  position: static;
  opacity: 1;
  background-color: #ffffff;
  box-shadow: var(--shadow-default);
}

.DropdownCommunity_hdr-DropdownCommunity__X_wja:hover .DropdownCommunity_hdr-DropdownCommunity__content--mobile__dYqg1 {
  position: absolute;
  top: 105%;
  width: 98%;
  margin: 0 1%;
  padding-top: 8px;
  padding-bottom: 8px;
}

.DropdownCommunity_hdr-DropdownCommunity__item__by3SB {
  list-style-type: none;
}

/** Dropdown link content */

.DropdownCommunity_hdr-DropdownCommunity__link__Ejy5j {
  display: flex;
  align-items: center;
  color: var(--themeButtonTextColor);
  text-decoration: none;
}

.DropdownCommunity_hdr-DropdownCommunity__link__Ejy5j:hover {
  background-color: var(--sk-color-grey-025);
  color: var(--themeButtonTextColor);
}

.DropdownCommunity_hdr-DropdownCommunity__link__Ejy5j::after {
  background-color: var(--themeButtonTextColor);
}

.DropdownCommunity_hdr-DropdownCommunity__linkTextWrapper__d37Kl {
  margin: 0;
}

.DropdownCommunity_hdr-DropdownCommunity__linkTitle__PQ54S {
  color: var(--themeMainTextColor);
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.DropdownCommunity_hdr-DropdownCommunity--nav__bQosD {
  display: flex;
  align-items: center;
}

.DropdownCommunity_hdr-DropdownCommunity--nav__bQosD svg {
  padding-left: var(--sk-space-4);
}

@media (max-width: 47.999rem) { /* --small-and-less-only */
  .DropdownCommunity_hdr-DropdownCommunity--nav__bQosD > svg {
    display: none;
  }
}

@media (min-width: 64rem) { /* --large-viewport */
  .DropdownCommunity_hdr-DropdownCommunity--mobile__Qepmd {
    position: relative;
  }

  .DropdownCommunity_hdr-DropdownCommunity--header__KvoZ9 {
    width: inherit;
  }

  .DropdownCommunity_hdr-DropdownCommunity__content___1isw {
    display: block;
    min-width: 345px;
    padding: 0;
    cursor: initial;
  }

  .DropdownCommunity_hdr-DropdownCommunity__content--left__6B9lO {
    right: inherit;
    left: 0;
  }

  .DropdownCommunity_DropdownCommunity__list__OS6Fe {
    padding: 8px;
  }

  .DropdownCommunity_hdr-DropdownCommunity__X_wja:hover .DropdownCommunity_hdr-DropdownCommunity__content___1isw {
    position: absolute;
    box-shadow: 0 10px 20px 0 rgb(0 0 0 / 20%);
  }

  .DropdownCommunity_hdr-DropdownCommunity__X_wja:hover .DropdownCommunity_hdr-DropdownCommunity__content--mobile__dYqg1 {
    position: absolute;
    top: 80%;
    width: inherit;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .DropdownCommunity_hdr-DropdownCommunity__link__Ejy5j {
    padding: 8px;
  }
}

/******************************
  UI Shift
*******************************/
/* stylelint-disable plugin/selector-bem-pattern */

[data-theme="jobteaser-uishift"] .DropdownCommunity_hdr-DropdownCommunity__content___1isw {
  border-radius: var(--sk-radius-8);
}

[data-theme="jobteaser-uishift"] .DropdownCommunity_hdr-DropdownCommunity__linkTitle__PQ54S {
  color: var(--sk-color-black);
}

}
@layer base {
  .LocalesDropdown_hdr-DropdownMenu__QB7HM {
  font-size: 1rem;
}

.LocalesDropdown_hdr-DropdownMenu-item__HLyzt {
  display: flex;
  position: relative;
  outline: none;
}

@media (max-width: 767px) {
  .LocalesDropdown_hdr-DropdownMenu-item__HLyzt {
    justify-content: center;
  }
}

.LocalesDropdown_hdr-DropdownMenu-item__HLyzt:focus-within {
  pointer-events: none;
}

.LocalesDropdown_hdr-DropdownMenu-link__L_WIh {
  display: flex;
  align-items: center;
  padding: var(--sk-space-12);
  border: none;
  background-color: transparent;
  font-family: var(--fontFamily);
  white-space: nowrap;
}

.LocalesDropdown_hdr-DropdownMenu-link__L_WIh::after,
.LocalesDropdown_hdr-DropdownMenu-link__L_WIh .LocalesDropdown_hdr-DropdownMenu-arrowIcon__8gse3 {
  margin: 0.125rem 0 0 0.5rem;
  font-size: 1.5em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: var(--font-weight-regular);
  font-feature-settings: "liga";
  text-rendering: optimizelegibility;
  word-wrap: normal;
  vertical-align: middle;
}

.LocalesDropdown_hdr-DropdownMenu-submenu__eSZjR {
  position: absolute;
  top: 100%;
  right: 0;
  width: 1px;
  height: 1px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  box-shadow: var(--shadow-default);
  list-style: none;
  pointer-events: auto;
}

.LocalesDropdown_hdr-DropdownMenu-item__HLyzt:focus-within .LocalesDropdown_hdr-DropdownMenu-submenu__eSZjR {
  z-index: var(--z-up-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  height: auto;
  padding: 8px;
  clip: auto;
  background: var(--sk-color-white);
}

.LocalesDropdown_hdr-DropdownMenu-submenuLink__xY0Ph {
  display: flex;
  align-items: center;
  padding: 8px;
  text-decoration: none;
}

.LocalesDropdown_hdr-DropdownMenu-submenuLink__xY0Ph:hover {
  background-color: var(--sk-color-grey-025);
}

.LocalesDropdown_hdr-DropdownMenu-linkIcon__gYUe3 {
  display: flex;
  align-items: center;
}

.LocalesDropdown_hdr-DropdownMenu-link--flagOnBottom__hT4w4 {
  padding: 0.8125rem 0.75rem;
  border-radius: var(--sk-radius-4);
  background-color: var(--sk-color-white);
  color: var(--sk-color-grey-500);
  font-size: 0.875rem;
  font-weight: var(--font-weight-semibold);
}

.LocalesDropdown_hdr-DropdownMenu-link--flagOnBottom__hT4w4 .LocalesDropdown_hdr-DropdownMenu-linkIcon__gYUe3 {
  padding-right: 0.5rem;
}

.LocalesDropdown_hdr-DropdownMenu-item--flag__gso8v .LocalesDropdown_hdr-DropdownMenu-submenuLinkText__5T4xT {
  margin-left: 12px;
  color: var(--sk-color-grey-600);
  font-size: 0.75rem;
  font-weight: var(--font-weight-regular);
  line-height: 1.25rem;
}

.LocalesDropdown_hdr-DropdownMenu-item--flag__gso8v .LocalesDropdown_hdr-DropdownMenu-submenuLinkItem__S_pk7 {
  display: flex;
  align-items: center;
  margin: 0;
}

.LocalesDropdown_hdr-DropdownMenu-item--flag__gso8v .LocalesDropdown_hdr-DropdownMenu-submenuItem__TkiGX:focus-within,
.LocalesDropdown_hdr-DropdownMenu-item--flag__gso8v .LocalesDropdown_hdr-DropdownMenu-submenuItem--selected__AyDBy {
  background-color: var(--sk-color-grey-025);
}

@media (max-width: 63.999rem) { /* --medium-and-less-only */
  .LocalesDropdown_hdr-DropdownMenu-item--flagOnTop__aSqPh {
    width: 100%;
  }

  .LocalesDropdown_hdr-DropdownMenu-item__HLyzt:focus-within .LocalesDropdown_hdr-DropdownMenu-submenu__eSZjR {
    left: 30px;
    width: calc(100% - 30px * 2);
  }

  .LocalesDropdown_hdr-DropdownMenu-link--flagOnTop__hudqf {
    width: 100%;
    padding: 1.125rem 1.875rem;
    border: thin solid var(--sk-color-grey-200);
    border-radius: var(--sk-radius-4);
  }

  .LocalesDropdown_hdr-DropdownMenu-link--flagOnTop__hudqf .LocalesDropdown_hdr-DropdownMenu-linkIcon__gYUe3 {
    padding-right: 0.5rem;
  }

  .LocalesDropdown_hdr-DropdownMenu-link--flagOnTop__hudqf .LocalesDropdown_hdr-DropdownMenu-linkLabel__NyaSU,
  .LocalesDropdown_hdr-DropdownMenu-link--flagOnBottom__hT4w4 .LocalesDropdown_hdr-DropdownMenu-linkLabel__NyaSU {
    flex: 1;
    font-weight: var(--font-weight-semibold);
  }

  .LocalesDropdown_hdr-DropdownMenu-item--flag__gso8v:focus-within .LocalesDropdown_hdr-DropdownMenu-submenu__eSZjR {
    top: -300px;
    left: 0;
    width: 100%;
    height: 300px;
    padding: 0;
    overflow-y: auto;
    border: thin solid var(--sk-color-grey-100);
    box-shadow: var(--shadow-default);
  }

  .LocalesDropdown_hdr-DropdownMenu-item--flagOnBottom__lyUCF:focus-within .LocalesDropdown_hdr-DropdownMenu-submenu__eSZjR {
    top: inherit;
    bottom: calc(100% + 1px);
    height: auto;
    padding: 8px;
  }

  .LocalesDropdown_hdr-DropdownMenu-item--flag__gso8v .LocalesDropdown_hdr-DropdownMenu-submenuItem__TkiGX {
    border-bottom: thin solid var(--sk-color-grey-100);
  }

  .LocalesDropdown_hdr-DropdownMenu-item--flagOnBottom__lyUCF:focus-within .LocalesDropdown_hdr-DropdownMenu-submenuItem__TkiGX {
    border: 0;
  }

  .LocalesDropdown_hdr-DropdownMenu-item--flag__gso8v .LocalesDropdown_hdr-DropdownMenu-submenuLinkItem__S_pk7 {
    padding: 16px 20px;
  }
}

@media (min-width: 64rem) { /* --large-viewport */
  .LocalesDropdown_hdr-DropdownMenu__QB7HM {
    display: flex;
    margin: 0;
    padding: 0;
    font-size: 0.875rem;
    font-weight: var(--font-weight-semibold);
  }

  .LocalesDropdown_hdr-DropdownMenu-item__HLyzt:focus-within .LocalesDropdown_hdr-DropdownMenu-submenu__eSZjR {
    box-sizing: border-box;
    width: 345px;
    max-height: 50vh;
    overflow-y: visible;
  }

  .LocalesDropdown_hdr-DropdownMenu-link--flagOnTop__hudqf .LocalesDropdown_hdr-DropdownMenu-linkLabel__NyaSU {
    display: none;
  }

  .LocalesDropdown_hdr-DropdownMenu-link--flagOnTop__hudqf .LocalesDropdown_hdr-DropdownMenu-linkIcon__gYUe3 {
    padding-right: 0;
  }

  .LocalesDropdown_hdr-DropdownMenu-item--flag__gso8v:focus-within .LocalesDropdown_hdr-DropdownMenu-submenu__eSZjR {
    width: inherit;
  }

  .LocalesDropdown_hdr-DropdownMenu-item--flagOnTop__aSqPh:focus-within .LocalesDropdown_hdr-DropdownMenu-submenu__eSZjR,
  .LocalesDropdown_hdr-DropdownMenu-item--flagOnTop__aSqPh .LocalesDropdown_hdr-DropdownMenu-submenuLinkItem__S_pk7 {
    padding: 8px;
  }

  .LocalesDropdown_hdr-DropdownMenu-item--flagOnBottom__lyUCF:focus-within .LocalesDropdown_hdr-DropdownMenu-submenu__eSZjR {
    display: flex;
    top: inherit;
    right: 0;
    bottom: calc(100% + 1px);
    flex-wrap: wrap;
    width: auto;
    padding: 8px;
    border: thin solid var(--sk-color-grey-100);
    border-radius: var(--sk-radius-4);
  }

  .LocalesDropdown_hdr-DropdownMenu-item--flagOnBottom__lyUCF:focus-within .LocalesDropdown_hdr-DropdownMenu-submenuItem__TkiGX {
    box-sizing: border-box;
    width: 100%;
  }

  .LocalesDropdown_hdr-DropdownMenu-item--flagOnBottom__lyUCF:focus-within .LocalesDropdown_hdr-DropdownMenu-submenuLink__xY0Ph {
    padding: 12px 8px;
  }

  .LocalesDropdown_hdr-DropdownMenu-linkIcon__gYUe3 {
    display: flex;
    padding-right: 0.5rem;
  }

  .LocalesDropdown_hdr-DropdownMenu-link__L_WIh {
    cursor: pointer;
  }

  .LocalesDropdown_hdr-DropdownMenu-item__HLyzt:focus-within .LocalesDropdown_hdr-DropdownMenu-link__L_WIh::after,
  .LocalesDropdown_hdr-DropdownMenu-item__HLyzt:focus-within .LocalesDropdown_hdr-DropdownMenu-link__L_WIh .LocalesDropdown_hdr-DropdownMenu-arrowIcon__8gse3 {
    transform: rotate(180deg);
    transition: transform 0.1s ease;
  }
}

/******************************
  UI Shift
*******************************/
/* stylelint-disable plugin/selector-bem-pattern */

[data-theme="jobteaser-uishift"] .LocalesDropdown_hdr-DropdownMenu-link__L_WIh {
  color: var(--sk-color-black);
}

[data-theme="jobteaser-uishift"] .LocalesDropdown_hdr-DropdownMenu-link--active__aUi0s {
  color: var(--color-primary);
}

[data-theme="jobteaser-uishift"] .LocalesDropdown_hdr-DropdownMenu-link--active__aUi0s::after {
  background-color: var(--color-primary);
}

[data-theme="jobteaser-uishift"] .LocalesDropdown_hdr-DropdownMenu-submenu__eSZjR,
[data-theme="jobteaser-uishift"] .LocalesDropdown_hdr-DropdownMenu-link--flagOnBottom__hT4w4,
[data-theme="jobteaser-uishift"] .LocalesDropdown_hdr-DropdownMenu-submenuLink__xY0Ph,
[data-theme="jobteaser-uishift"] .LocalesDropdown_hdr-DropdownMenu-item--flag__gso8v .LocalesDropdown_hdr-DropdownMenu-submenuItem--selected__AyDBy {
  border-radius: var(--sk-radius-8);
}

@media (max-width: 63.999rem) { /* --medium-and-less-only */
  [data-theme="jobteaser-uishift"] .LocalesDropdown_hdr-DropdownMenu-link--flagOnTop__hudqf {
    border-radius: var(--sk-radius-8);
  }
}

@media (min-width: 64rem) { /* --large-viewport */
  [data-theme="jobteaser-uishift"] .LocalesDropdown_hdr-DropdownMenu-item--flagOnBottom__lyUCF:focus-within .LocalesDropdown_hdr-DropdownMenu-submenu__eSZjR {
    border-radius: var(--sk-radius-8);
  }
}

}
@layer base {
  .AppFoLayout_main__PwkKD {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

}
@layer base {
  .Socials_main___iTsF {
  display: flex;
  position: relative;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.Socials_item__CElhu {
  flex: 1 0 auto;
  margin: 0.5rem;
  text-align: center;
}

.Socials_icon__SS3zT {
  width: auto;
  height: 20px;
}

}
